import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class VisitaService {
  getAccesosEmpleadoParaReporteByFecha(data:any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/obtener-accesos-por-empleado", data);
    
  }

  getAsistenciaEmpleadosParaReporteByFecha(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/visitas-para-reporte-empleados", data);
  }

  ValidaSiEsEntradaOSalida(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/validar-si-es-entrada-o-salida", data);

  }
  imprimirComprobante(idvisita: any) {
    return this.http.get(keys.urlBackend + keys.tabla.visita + "/imprimir-comprobante/" + idvisita);

  }
  archivarDesarchivar(idvisita: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.visita + "/desarchivar-archivar/" + idvisita);

  }
  getVisitasFiltradasParaCheckListArchivadas(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/filtrar-para-checklist/archivadas", data);

  }
  validarCheckListSalida(data: { idvisita: any; items: any[]; }) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/validar-checklist-salida", data);

  }
  validarCheckListEntrada(data: { idvisita: any; items: any[]; }) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/validar-checklist-entrada", data);

  }
  noAplicarCheckList(idvisita: number) {
    return this.http.delete(keys.urlBackend + keys.tabla.visita + "/no-aplicar-checklist/" + idvisita);
  }
  getVisitasParaReporteByFechaCanceladas(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/visitas-para-reporte/canceladas", data);

  }
  RegistrarVisitaAgendada(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/registrar-visita-agendada", data);
  }


  constructor(private http: HttpClient) { }

  public getVisitasParaCancelacionByFecha(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/visitas-para-cancelar", data);
  }



  public disableEnable(idvisita: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.visita + "/" + idvisita);
  }


  getVisitasParaReporteByFecha(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/visitas-para-reporte", data);

  }

  getVisitasParaMonitoreoByFecha(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/visitas-para-monitoreo", data);

  }



  getVisitasFiltradasParaCheckList(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/filtrar-para-checklist", data);

  }

  getContadoresByFecha(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/contadores", data);
  }


  getVisitaByCodigoSalida(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/obtener-datos-para-salida", data);

  }


  registrarSalida(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/registrar-salida", data);
  }
  getFotosByCodigoSalida(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/obtener-datos-para-salida-fotos", data);
  }



  getVisitaByCodigoEntrada(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/obtener-datos-para-entrada", data);
  }
  registrarEntrada(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/registrar-salida", data);
  }
  getFotosByCodigoEntrada(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/obtener-datos-para-salida-fotos", data);
  }

  RegistrarVisitaEnSitio(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/registrar-visita-en-sitio", data);

  }

  RegistrarEntrada(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.visita + "/registrar-entrada", data);

  }



}