import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class ParametrizacionService {


  constructor(private http: HttpClient) { }
  cargarValor(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.parametrizacion+"/cargar-valor", data);
  }

  obtenerValor(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.parametrizacion+"/obtener-valor", data);
  }
  cargarFoto(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.parametrizacion+"/cargar-foto", data);
  }
  update(idparametrizacion: any, data: any) {
    return this.http.put(keys.urlBackend + keys.tabla.parametrizacion + "/" + idparametrizacion, data);
  }
  create(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.parametrizacion, data);
  }
  
}
