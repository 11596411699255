import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { decodeToken } from '../../helpers/token';
import { PuntoDeVentaService } from '../../services/punto-de-venta.service';

@Component({
  selector: 'app-imprimir-ticket',
  templateUrl: './imprimir-ticket.component.html',
  styleUrls: ['./imprimir-ticket.component.scss']
})
export class ImprimirTicketComponent implements OnInit {

  nombreCerrada: string = ""
  title = 'app';
  elementType = 'url';
  observaciones = "";
  paginaWeb = "";
  numeroCasa = "";
  value = "";
  messageAgradecimiento = "Gracias por su Preferencia.";
  direccionLogo = "";
  folio = 0;
  costoTotal = "";
  fechaPago = "";
  reglamento="";
  fechaImpresion: any = new Date().toLocaleDateString();
  horaImpresion: any = new Date().toLocaleTimeString();
  productos: any = []
  token: any = "";
  idcerrada: any;
  nombreEmpresa: any;
  imagenEmpresa: any;
  constructor(private ruta: ActivatedRoute, private puntoDeVentaService: PuntoDeVentaService) { }

  ngOnInit(): void {
    this.folio = this.ruta.snapshot.params.idregistro_movimiento;

    this.token = decodeToken(localStorage.getItem("tokenVisitasSystem"));
    this.idcerrada = this.token.idcerrada;
    this.puntoDeVentaService.obtenerTicket(this.folio, this.idcerrada).toPromise().then((resp: any) => {
      console.log(resp.logo);
      this.nombreCerrada = resp.nombreCerrada;
      this.paginaWeb = resp.web;
      this.nombreEmpresa = resp.nombreEmpresa;
      this.imagenEmpresa = resp.imagenEmpresa;
      this.numeroCasa = resp.nombreVen
      this.direccionLogo = resp.logo
      this.folio = resp.idregistro_movimiento;
      this.costoTotal = resp.costoFinal;
      this.fechaPago = resp.fecha_de_registro;
      this.productos = resp.items
      this.observaciones = resp.observaciones;
      this.value = resp.web;
      this.reglamento=resp.reglamentoEmpresa;
    });
    setTimeout(this.sendImprimir, 2000);
  }


  imprimir() {
    const btnPrint = document.getElementById("btnPrint");
    btnPrint.addEventListener("click", () => {
      window.print();
    });
  }

  sendImprimir() {
    window.print();
    window.close();
  }

}
