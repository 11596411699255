import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class CasaService {

  constructor(private http: HttpClient) { }

  public listAllVisiblesAndAll() {
    return this.http.get(keys.urlBackend + keys.tabla.casa);
  }

  public listAllVisiblesAndAllByCerrada(idcerrada: any) {
    return this.http.get(keys.urlBackend + keys.tabla.casa+"/cerrada/"+idcerrada);
  }
  public listAllVisiblesAndActivesByCerrada(idcerrada: any) {
    return this.http.get(keys.urlBackend + keys.tabla.casa+"/cerrada/actives/"+idcerrada);
  }
  

  public disableEnable(idcasa: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.casa + "/" + idcasa);
  }
  public create(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.casa, data);
  }

  
  public update(data: any,idcasa:any) {
    return this.http.put(keys.urlBackend + keys.tabla.casa+"/"+idcasa, data);

  }

    

  public restablecerPassword(data: any,idcerrada:any) {
    return this.http.put(keys.urlBackend + keys.tabla.casa+"/restablecer/"+idcerrada, data);

  }


}
