import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { decodeToken } from '../helpers/token';

import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class KeyRoleGuard implements CanActivate {

  constructor(private _location: Location) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let key_role = route.data["key_role"] as string;
    let roles: any[] = [];
    roles = decodeToken(localStorage.getItem("tokenVisitasSystem")).permisosAccesos;

    if (roles.indexOf(key_role) === -1) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        title: "No tienes Acceso a Este modulo",
        icon: 'error'
      }).then(() => {
        this._location.back();
        return false;
      });
    } else {
      return true;
    }
  }


}
