import { Component, OnInit } from '@angular/core';
import { decodeToken } from 'src/app/modulos/helpers/token';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  token: any;
  paginaWeb: any;
  nombreEmpresa: any;
  date: number;

  constructor() { }
  

  ngOnInit(): void {
    this.date = Date.now();
    this.token = decodeToken(localStorage.getItem("tokenVisitasSystem"));
    this.paginaWeb = this.token.paginaWeb;
    this.nombreEmpresa = this.token.nombreEmpresa;
  }

}
