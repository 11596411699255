import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { ImprimirTicketComponent } from './modulos/punto-de-venta/imprimir-ticket/imprimir-ticket.component';
import { LoginComponent } from './modulos/residentes/login/login.component';
import { TicketComponent } from './modulos/punto-de-venta/ticket/ticket.component';
import { decodeToken } from './modulos/helpers/token';
import { InicioComponent } from './modulos/inicio/inicio.component';
import { ImprimirComprobanteComponent } from './modulos/visitas/imprimir-comprobante/imprimir-comprobante.component';
import { KeyRoleGuard } from './modulos/guards/key-role.guard';
import { CargaDeDocumentosComponent } from './modulos/visitas/carga-de-documentos/carga-de-documentos.component';



let is_empresa: Boolean = JSON.parse(localStorage.getItem("validateIsEmpresa"));

let cerrada_o_empresa_label = is_empresa ? "Empresa" : "Cerrada";
let cerrada_o_empresa_text = is_empresa ? "empresa" : "cerrada";


let casa_o_departamento_label = is_empresa ? "Departamento" : "Casa";
let casa_o_departamento_text = is_empresa ? "departamento" : "casa";


let residente_o_empleado_label = is_empresa ? "Empleado" : "Residente";
let residente_o_empleado_text = is_empresa ? "empleado" : "residente";
/* console.log("localshotarge",localStorage.getItem("validateIsEmpresa"));
console.log("is_empresa",is_empresa);
console.log("residente_o_empleado_text",residente_o_empleado_text); */


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'punto-de-venta/imprimir-ticket/:idregistro_movimiento',
    component: ImprimirTicketComponent,
    canActivate: []
  },
  {
    path: 'visitas/imprimir-comprobante/:idvisita',
    component: ImprimirComprobanteComponent,
    canActivate: [KeyRoleGuard], data: { key_role: "VISITAS_IMPRIMIR_COMPROBANTE_KEY" }
  },
  {
    path: 'punto-de-venta/ticket/:folio/:token',
    component: TicketComponent,
    canActivate: []
  },
  {
    path: 'carga-de-documentos/:idcerrada/:hashVisita',
    component: CargaDeDocumentosComponent,
    canActivate: []
  },
  { path: `login`, component: LoginComponent },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      /* {
        path: '**',
        redirectTo: 'inicio',
        pathMatch: 'full'
      }, */
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      {
        path:'inicio',
        component:InicioComponent
      },
      {
        path: 'administracion',
        loadChildren: () => import('./modulos/administracion/administracion.module').then(m => m.AdministracionModule)
      },
      {
        path: 'punto-de-venta',
        loadChildren: () => import('./modulos/punto-de-venta/punto-de-venta.module').then(m => m.PuntoDeVentaModule)
      },
      {
        path: 'visitas',
        loadChildren: () => import('./modulos/visitas/visitas.module').then(m => m.VisitasModule)
      },
      {
        path: 'buzon',
        loadChildren: () => import('./modulos/buzon/buzon.module').then(m => m.BuzonModule)
      },

      {
        path: 'otro',
        loadChildren: () => import('./modulos/otro/otro.module').then(m => m.OtroModule)
      },
      {
        path: 'grupos-de-acceso',
        loadChildren: () => import('./modulos/grupos-de-acceso/grupos-de-acceso.module').then(m => m.GruposDeAccesoModule)
      },
      {
        path: 'extras',
        loadChildren: () => import('./modulos/extras/extras.module').then(m => m.ExtrasModule)
      },


      {
        path: `${residente_o_empleado_text}`,
        loadChildren: () => import(`./modulos/residentes/residentes.module`).then(m => m.ResidentesModule)
      },
      

      {
        path: `panel-de-acceso`,
        loadChildren: () => import(`./modulos/panel-de-acceso/panel-de-acceso.module`).then(m => m.PanelDeAccesoModule)
      },

      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'ui-components',
        loadChildren: () => import('./views/pages/ui-components/ui-components.module').then(m => m.UiComponentsModule)
      },
      {
        path: 'advanced-ui',
        loadChildren: () => import('./views/pages/advanced-ui/advanced-ui.module').then(m => m.AdvancedUiModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./views/pages/form-elements/form-elements.module').then(m => m.FormElementsModule)
      },
      {
        path: 'advanced-form-elements',
        loadChildren: () => import('./views/pages/advanced-form-elements/advanced-form-elements.module').then(m => m.AdvancedFormElementsModule)
      },
      {
        path: 'charts-graphs',
        loadChildren: () => import('./views/pages/charts-graphs/charts-graphs.module').then(m => m.ChartsGraphsModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      {
        path: 'areas-comunes',
        loadChildren: () => import('./modulos/areas-comunes/areas-comunes.module').then(m => m.AreasComunesModule)
      },
     
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
