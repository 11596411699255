import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { decodeToken } from '../../helpers/token';
import { PuntoDeVentaService } from '../../services/punto-de-venta.service';
import { VisitaService } from '../../services/visita.service';

@Component({
  selector: 'app-imprimir-comprobante',
  templateUrl: './imprimir-comprobante.component.html',
  styleUrls: ['./imprimir-comprobante.component.scss']
})
export class ImprimirComprobanteComponent implements OnInit {
  title = 'app';
  elementType = 'url';
  value = '';

  nombreCerrada: string = ""
  observaciones = "";
  paginaWeb = "";
  numeroCasa = "";
  messageAgradecimiento = "Gracias por su Preferencia.";
  direccionLogo = "";
  folio = 0;
  costoTotal = "";
  fechaPago = "";
  reglamento = "";
  fechaImpresion: any = new Date().toLocaleDateString();
  horaImpresion: any = new Date().toLocaleTimeString();
  productos: any = []
  token: any = "";
  idcerrada: any;
  nombreEmpresa: any;
  imagenEmpresa: any;
  idvisita: any;

  constructor(private ruta: ActivatedRoute, private visitaService: VisitaService) { }

  ngOnInit(): void {
    this.value = this.ruta.snapshot.params.idvisita;

    this.token = decodeToken(localStorage.getItem("tokenVisitasSystem"));
    this.idcerrada = this.token.idcerrada;
/*  */
    /* 
    setTimeout(this.sendImprimir, 2000); */
  }


  imprimir() {
    const btnPrint = document.getElementById("btnPrint");
    btnPrint.addEventListener("click", () => {
      window.print();
    });
  }

  sendImprimir() {
    window.print();
    window.close();
  }

}
