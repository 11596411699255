import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class ItemChecklistService {
  cargarDocumentoRemoto(hashVisita: any,data: any) {
    return this.http.put(`${keys.urlBackend}${keys.tabla.item_checklist}/subir-documento-remoto/${hashVisita}`, data);

  }


  update(data: any, iditem_checklist: any) {
    return this.http.put(keys.urlBackend + keys.tabla.item_checklist + "/" + iditem_checklist, data);
  }
  listAllVisiblesAndActivesByVisita(idvisita: any) {
    return this.http.get(keys.urlBackend + keys.tabla.item_checklist + "/" + idvisita);

  }
  disableEnable(iditem_checklist: number) {
    return this.http.delete(keys.urlBackend + keys.tabla.item_checklist + "/" + iditem_checklist)
  }

  disableEnableSubItems(iditem_checklist: number) {
    return this.http.delete(keys.urlBackend + keys.tabla.item_checklist + "/subitems/" + iditem_checklist)
  }

  disableEnableDocumento(iditem_checklist: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.item_checklist + "/documento/" + iditem_checklist)

  }

  create(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.item_checklist, data);
  }


  listAllVisiblesAndAllByCerrada(idcerrada: any) {
    return this.http.get(keys.urlBackend + keys.tabla.item_checklist + "/cerrada/" + idcerrada);

  }

  constructor(private http: HttpClient) { }

  public listAllVisiblesAndActivesByCerrada(idcerrada: number) {
    return this.http.get(keys.urlBackend + keys.tabla.item_checklist + "/actives/" + idcerrada);
  }

  
  public listAllVisiblesAndActivesByCerradaRemoto(idcerrada: number,idvisita:number) {
    return this.http.get(keys.urlBackend + keys.tabla.item_checklist + "/actives/remoto/" + idcerrada+"/"+idvisita);
  }

  public listAllVisiblesAndActivesByCerradaDocumentos(idcerrada: number, hashVisita: string) {
    return this.http.get(keys.urlBackend + keys.tabla.item_checklist + "/actives/" + idcerrada + "/documentos/" + hashVisita);
  }
  public obtenerDatos(hashVisita: string) {
    return this.http.get(keys.urlBackend + keys.tabla.item_checklist + "/obtenerDatos/" + hashVisita);
  }

}
