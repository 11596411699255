import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { keys } from 'src/environments/keys';
import { decodeToken } from '../helpers/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginResidentes(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.auth + "/login-residentes", data);
  }
  constructor(private http: HttpClient, private router: Router) { }

  public login(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.auth + "/login", data);
  }

  public refreshToken(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.auth + "/refresh-token", data);
  }



  public ObtenerToken(){
    return decodeToken(localStorage.getItem("tokenVisitasSystem"));
  }

  public logout() {
    /* localStorage.removeItem('isLoggedin'); */
    localStorage.clear();
    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login'])
    }
  }

}
