
export const keys = {
  /* urlBackend: "http://localhost:4000", */
    /* urlBackend: "https://visitashome.onrender.com", */
   /*  urlBackend: "https://visitasapp.fly.dev", */
    urlBackend: "https://tlatelolco.kayrosmexico.com:4000",
    protocol:"https://",
    /* host:"http://localhost:4200", */
    host:"https://visitashome.kayrosmexico.com",
    tabla:
    {
        cerrada: "/api/cerrada",
        casa: "/api/casa",
        cajon: "/api/cajon-estacionamiento",
        casaHasResidente: "/api/casa-has-residente",
        residente: "/api/residente",
        concepto: "/api/concepto",
        usuario: "/api/usuario",
        puntoDeVenta: "/api/punto-de-venta",
        tipoDePago: "/api/tipo-de-pago",
        queja: "/api/queja",
        sugerencia: "/api/sugerencia",
        aviso: "/api/aviso",
        visita: "/api/visita",
        membrete: "/api/membrete",
        acceso_pagina: "/api/acceso-pagina",
        tipo_de_usuario: "/api/tipo-de-usuario",
        auth: "/api/auth",
        parametrizacion: "/api/parametrizacion",
        proveedor: "/api/proveedor",
        reporte: "/api/reporte",
        importacion: "/api/importacion",
        servicios: "/api/servicio",
        correo: "/api/correo",
        grupo_de_acceso: "/api/grupo-de-acceso",
        reglas_de_negocio_para_acceso: "/api/reglas-de-negocio-para-acceso",
        generico: "/api/generico",
        estatus_checklist: "/api/estatus-checklist",
        item_checklist:"/api/item-checklist",
        archivos_visitas:"/api/archivos_visitas",
        panel:"/api/panel",
        area_comun:"/api/area-comun"


    },
    residente: {
        urlVisita: "/api/visita",
        urlAvisos: "/api/aviso",
        urlAvisosVencimiento: "/api/avisos-de-vencimiento",
        urlEncuestas: "/api/encuestas",
        urlFinanzas: "/api/finanzas-por-casa",
        urlHistorialDePagos: "/api/historial-de-pagos",
        urlQuejasYSugerencias: "/api/quejas-y-sugerencias",
        urlCasaHasResidente: "/api/casa-has-residente",
        urlConcepto: "/api/conceptos",
        urlHistorico: "/api/historico",
        urlCalle: "/api/calle",
        urlCasa: "/api/casa",
        urlAuth: "/auth"
    }

};
