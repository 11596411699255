import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modulos/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('isLoggedin')) {
      if (this.isTokenExpirado()) {
        this.authService.logout();
        this.router.navigate(['/auth/login']);
        return false;
      }
      // logged in so return true

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  isTokenExpirado(): boolean {
    let token = this.authService.ObtenerToken();
    let payload = token.exp;
    let now = new Date().getTime() / 1000;
    if (payload < now) {
      return true;
    }
    return false;
  }

}