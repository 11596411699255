import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WindowScrollController } from "@fullcalendar/core";
import Swal from "sweetalert2";
import { GenericoService } from "../../services/generico.service";
import { ItemChecklistService } from "../../services/item-checklist.service";

@Component({
  selector: "app-carga-de-documentos",
  templateUrl: "./carga-de-documentos.component.html",
  styleUrls: ["./carga-de-documentos.component.scss"],
})
export class CargaDeDocumentosComponent implements OnInit {
  idcerrada: any;
  hashVisita: any;
  isDataAvailable: boolean = false;
  data:any = {};
  constructor(
    private genericoService: GenericoService,
    private itemChecklistService: ItemChecklistService,
    private ruta: ActivatedRoute
  ) { }
  MAXIMO_TAMANIO_BYTES = 2000000;
  documentos: any[] = [];
  rowSelected: number;
  ngOnInit(): void {
    this.idcerrada = this.ruta.snapshot.params.idcerrada;
    this.hashVisita = this.ruta.snapshot.params.hashVisita;
    this.itemChecklistService
      .listAllVisiblesAndActivesByCerradaDocumentos(
        this.idcerrada,
        this.hashVisita
      )
      .toPromise()
      .then((resp: any) => {
        console.log("resp", resp);

        this.documentos = resp;
      });
    this.itemChecklistService
      .obtenerDatos(this.hashVisita)
      .toPromise()
      .then((resp: any) => {
        console.log("resp", resp);

        this.data = resp;
      });
  }
  async seleccionarArchivo(index: number) {
    let element: HTMLElement = document.querySelector(
      ".cargarArchivo"
    ) as HTMLElement;
    this.rowSelected = index;

    element.click();
  }
  handleUpload(event, item: any, index: number) {
    const file = event.target.files[0];
    console.log(file);

    index = this.rowSelected;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.documentos[index].cargando = true;

      if (file.size > this.MAXIMO_TAMANIO_BYTES) {
        const tamanioEnMb = this.MAXIMO_TAMANIO_BYTES / 1000000;

        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          title: `El tamaño máximo es ${tamanioEnMb} MB`,
          icon: "error",
        });
        // Limpiar
        this.documentos[index].cargando = false;
      } else {
        // Validación pasada. Envía el formulario o haz lo que tengas que hacer
        console.log(reader.result.toString());

        this.documentos[index].b64 = reader.result;
        this.documentos[index].b64Split = reader.result
          .toString()
          .split(",")[1];

        this.documentos[index].mimetype = file.type;
        console.log(file);

        const formData = new FormData();

        formData.append("documento", file);
        formData.append(
          "iditem_checklist",
          this.documentos[index].iditem_checklist
        );
        formData.append("mimetype", this.documentos[index].mimetype);

        this.itemChecklistService
          .cargarDocumentoRemoto(this.hashVisita, formData)
          .toPromise()
          .then((resp: any) => {
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              title: `Documento Cargado Exitosamente`,
              icon: "success",
            });
            this.documentos[index].documentoCargado = true;
          })
          .catch((err) => {
            this.documentos[index].documentoCargado = false;
            console.log(err);
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              title: `Error al Cargar el Documento`,
              icon: "error",
            });
          })
          .finally(() => {
            this.documentos[index].cargando = false;
          });
      }

      /* this.formVisita.controls["imagen1"].setValue(reader.result.toString().split(",")[1]) */
    };
  }
  verDocumento(item: any) {
    const byteCharacters = atob(item.b64Split);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: item.mimetype });
    var url = URL.createObjectURL(blob);
    window.open(url);
  }
}
