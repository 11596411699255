import { Component, OnInit } from '@angular/core';
import { GenericoService } from './modulos/services/generico.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nobleui-angular';
  constructor(private genericoService: GenericoService) {

  }

  async ngOnInit(): Promise<void> {
    let valor:Boolean = await this.genericoService.validarEmpresaOResidencial().toPromise().then((resp: any) => {


      return Boolean(resp.data);
    }).catch(() => {
      return false;
    });
    localStorage.setItem("validateIsEmpresa", valor.toString());
    console.log(localStorage.getItem("validateIsEmpresa"));

  }

}
