import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class CerradaService {

  disableEnableFinanzas(idcerrada: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.cerrada + "/finanzas/" + idcerrada);
  }


  verFinanzas(idcerrada: any) {
    return this.http.get(keys.urlBackend + keys.tabla.cerrada + "/ver-finanzas/" + idcerrada);
  }


  constructor(private http: HttpClient) { }

  public listAllVisiblesAndAll() {
    return this.http.get(keys.urlBackend + keys.tabla.cerrada);
  }
  listAllVisiblesAndActives() {
    return this.http.get(keys.urlBackend + keys.tabla.cerrada + "/actives");
  }


  public disableEnable(idcerrada: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.cerrada + "/" + idcerrada);
  }

  public create(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.cerrada, data);

  }

  cargarFoto(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.cerrada + "/cargar-foto", data);
  }


  public update(data: any, idcerrada: any) {
    return this.http.put(keys.urlBackend + keys.tabla.cerrada + "/" + idcerrada, data);

  }


  public asignarAdministrador(data: any, idcerrada: any) {
    return this.http.post(keys.urlBackend + keys.tabla.cerrada + "/asignar/administrador/" + idcerrada, data);

  }


}
