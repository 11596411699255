import { Component, OnInit } from '@angular/core';
import { ParametrizacionService } from '../services/parametrizacion.service';
import { io } from "socket.io-client";
import { keys } from 'src/environments/keys';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  urlImagen: string = "";
  id: string;
  codigo: string;
  constructor(private parametrizacionService: ParametrizacionService, private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
   
    this.urlImagen = await this.parametrizacionService.obtenerValor({ llave: "imagen_principal" }).toPromise().then((resp: any) => {
      return resp.valor;
    }).catch((error: any) => {
      console.log(error);

    })
  }

}
