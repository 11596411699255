import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class PuntoDeVentaService {
  cargarDocumento(idregistro_movimiento: any, data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/cargar-documento/" + idregistro_movimiento, data);
  }
  public sendTicket(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.correo + "/enviar-ticket", data);

  }

  constructor(private http: HttpClient) { }

  public getLastRowsActives() {
    return this.http.get(keys.urlBackend + keys.tabla.puntoDeVenta + "/ultimo-registros-actives");
  }


  public disable(idregistro_movimiento: any) {
    return this.http.delete(keys.urlBackend + keys.tabla.puntoDeVenta + "/" + idregistro_movimiento);
  }

  public generarhistorico(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/generar-historico", data);
  }



  public validarVenta(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/validar-venta", data);
  }


  public registrarVenta(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/registrar-venta", data);
  }

  
  public registrarVentaInversa(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/registrar-venta-inversa", data);
  }
  registrarCompra(data: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/registrar-compra", data);
  }


  corteDelDiaByFecha(data: any, idcerrada: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/generar-corte-del-dia/" + idcerrada, data);

  }


  corteDelDiaByFechaParaCarga(data: any, idcerrada: any) {
    return this.http.post(keys.urlBackend + keys.tabla.puntoDeVenta + "/generar-corte-del-dia/carga-de-documentos/" + idcerrada, data);

  }

  buscarFolio(idregistro_movimiento: any, idcerrada: any) {
    return this.http.get(keys.urlBackend + keys.tabla.puntoDeVenta + "/buscar-folio/" + idregistro_movimiento + "/" + idcerrada);
  }

  obtenerTicket(idregistro_movimiento: any, idcerrada: any) {
    return this.http.get(keys.urlBackend + keys.tabla.puntoDeVenta + "/obtener-ticket/" + idregistro_movimiento + "/" + idcerrada);
  }

}
