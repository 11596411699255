import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import { CasaService } from '../../services/casa.service';
import { CerradaService } from '../../services/cerrada.service';
import { GenericoService } from '../../services/generico.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  returnUrl: any;
  datosAjaxCalle: any;
  datosAjaxCasa: any;
  residente_o_empleado_text: string;
  is_empresa: Boolean;
  cerrada_o_empresa_label: string;
  cerrada_o_empresa_text: string;
  casa_o_departamento_label: string;
  casa_o_departamento_text: string;
  residente_o_empleado_label: string;
  date: number;
  fieldTextType: boolean = false;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private cerradaService: CerradaService, public casaService: CasaService, private genericoService: GenericoService) { }

  async ngOnInit(): Promise<void> {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.date = Date.now();
    this.buildForms();
    this.cargarDatos();
    let valor: Boolean = await this.genericoService.validarEmpresaOResidencial().toPromise().then((resp: any) => {

      return Boolean(resp.data);
    }).catch(() => {
      return false;
    });

    this.is_empresa = valor;

    localStorage.setItem("validateIsEmpresa", valor.toString());

    this.cerrada_o_empresa_label = this.is_empresa ? "Empresa" : "Cerrada";
    this.cerrada_o_empresa_text = this.is_empresa ? "empresa" : "cerrada";


    this.casa_o_departamento_label = this.is_empresa ? "Departamento" : "Casa";
    this.casa_o_departamento_text = this.is_empresa ? "departamento" : "casa";


    this.residente_o_empleado_label = this.is_empresa ? "Empleado" : "Residente";
    this.residente_o_empleado_text = this.is_empresa ? "empleado" : "residente";
  }


  public formLogin: FormGroup;

  private buildForms() {
    this.formLogin = this.formBuilder.group({
      idcerrada: ['', [Validators.required]],
      idcasa: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }



  private cargarDatos() {
    this.cerradaService.listAllVisiblesAndActives().toPromise().then((resp: any) => {
      console.log(resp);
      this.datosAjaxCalle = resp;
    }).catch((error: any) => {
      console.log(error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        title: "No se Cargaron Las Cerradas",
        icon: 'error'
      });
    })
  }


  onChangeCalle(event: Event) {
    let idcalle: any = event.target['options'][event.target['options'].selectedIndex].value;

    this.casaService.listAllVisiblesAndActivesByCerrada(idcalle).toPromise().then((resp: any) => {
      console.log(resp);
      this.datosAjaxCasa = resp;
    }).catch((err: any) => {
      console.log(err);
      this.datosAjaxCasa = [];
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        title: "No se Cargaron Las Casas",
        icon: 'error'
      });
    });
  }

  submitForm() {
    if (this.formLogin.valid) {
      this.formLogin.disable();
      $("#spinnerAgregar").show();
      console.log(this.formLogin.value);
      this.authService.loginResidentes(this.formLogin.value).toPromise().then((resp: any) => {
        console.log(resp);

        $("#spinnerAgregar").hide();
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: resp.message,
          icon: 'success'
        }).then(() => {

          this.formLogin.enable();
          localStorage.setItem('isLoggedin', 'true');
          localStorage.setItem("tokenVisitasSystem", resp.token);
          if (localStorage.getItem('isLoggedin')) {
            this.router.navigate([this.returnUrl]);
          }
        });


      }).catch((err: any) => {
        console.log(err);
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          title: err.error.message,
          icon: 'error'
        });
        this.formLogin.enable();
        $("#spinnerAgregar").hide();
      });
    }
    else {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        title: 'Todos los campos son obligatorios',
        icon: 'error'
      });

    }
  }

  verPassword(event) {
    this.fieldTextType = (this.fieldTextType == true) ? false : true;
  }

}
