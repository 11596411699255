import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { decodeToken } from 'src/app/modulos/helpers/token';
import { AuthService } from 'src/app/modulos/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;

  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  paginaWeb: any;
  nombreEmpresa: any;
  imagenEmpresa: any;
  is_empresa: any;
  puntoDeVentaConceptosIngreso: boolean;
  puntoDeVentaProveedores: boolean;
  puntoDeVentaConceptosEgresos: boolean;
  puntoDeVentaReporteDeFinanzas: boolean;
  puntoDeVentaBuscarFolio: boolean;
  puntoDeVentaRegistrarVenta: boolean;
  puntoDeVentaRegistrarCompra: boolean;
  puntoDeVentaCorteDelDia: boolean;
  puntoDeVentaHistorico: boolean;
  puntoDeVentaCancelacionDeMovimientos: boolean;
  banderaAccesoVisitasVisitasCanceladas: boolean;
  banderaExtrasServicios: boolean;
  banderaAccesoGrupoDeAccesoAdministracion: boolean;
  banderaAccesoGrupoDeAccesoBloqueoDeUsuarios: boolean;
  banderaSubMenuCatalogosPuntoDeVenta: boolean;
  puntoDeVentaReporteDeFinanzasCerradaOEmpresa: boolean;
  banderaSubMenuReportesPuntoDeVenta: boolean;
  banderaGruposDeAcceso: boolean;
  banderaExtras: boolean;
  puntoDeVentaCargaDeDocumentos: boolean;
  banderaImportarcionMasivaFlowserve: boolean = false;
  banderaPanelDeAccesoPanel: boolean = false;
  banderaPanelDeAccesoPuerta: boolean = false;
  banderaPanelDeAccesoLector: boolean = false;
  banderaPanelDeAcceso: boolean = false;
  baderaVisitasCatalogosItemsChecklist: boolean;
  banderaSubMenuCatalogosVisitas: boolean;
  banderaAccesoVisitasArchivadas: boolean;
  banderaAccesoAreasComunesAdministracion: boolean;
  banderaAccesoAreasComunesReservacion: boolean;
  banderaAccesoAreasComunesCancelacion: boolean;
  banderaAreasComunes: boolean;
  banderaAccesoVisitasLeerQR: boolean;
  banderaAccesoVisitasImprimirQR: boolean;
  baderaVisitasReportesVisitas: boolean;
  baderaVisitasReportesAccesos: boolean;
  banderaSubMenuReportesVisitas: boolean;
  banderaVisitasCatalogosCodigosQR: boolean;
  puntoDeVentaReporteDeFinanzasDetalladas: boolean;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, router: Router, private authService: AuthService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }
  token: any;
  permisosAccesos: any = [];


  banderaAdministracion = false;
  banderaReportes = false;
  banderaPuntoDeVenta = false;
  banderaVisitas = false;
  banderaBuzon = false;
  banderaOtro = false;
  banderaResidente = false;

  //ADMINISTRACION 
  banderaAccesoAdministracionCerrada = false;
  banderaAccesoAdministracionCasa = false;
  banderaAccesoAdministracionResidente = false;
  banderaAccesoAdministracionAdministradoresCerradas = false;
  banderaAccesoAdministracionConcepto = false;
  banderaAccesoAdministracionConceptoGastos = false;
  banderaAccesoAdministracionProveedores = false;
  banderaAccesoAdministracionTipoDeUsuarios = false;
  banderaAccesoAdministracionAccesos = false;
  banderaAccesoAdministracionServicios = false;

  //REPORTES
  banderaAccesoReportesFinanzasPorCerrada = false;
  //PUNTO DE VENTA
  banderaAccesoPuntoDeVentaBuscarFolio = false;
  banderaAccesoPuntoDeVentaRegistrarVenta = false;
  banderaAccesoPuntoDeVentaRegistrarCompra = false;
  banderaAccesoPuntoDeVentaCorteDelDia = false;
  banderaAccesoPuntoDeVentaHistorico = false;
  banderaAccesoPuntoDeVentaCancelacionDeMovimientos = false;

  //VISITAS
  banderaAccesoVisitasLeerEntrada = false;
  banderaAccesoVisitasLeerSalida = false;
  banderaAccesoVisitasVisitaEnSitio = false;
  banderaAccesoVisitasVisitaAgendada = false;
  banderaAccesoVisitasMonitoreo = false;
  banderaAccesoVisitasReporte = false;
  banderaAccesoVisitasCancelacionDeVisitas = false;
  banderaAccesoVisitasValidacionDeCheckList = false;



  //BUZON
  banderaAccesoBuzonAvisos = false;
  banderaAccesoBuzonQuejas = false;
  banderaAccesoBuzonSugerencias = false;




  //OTRO
  banderaAccesoOtroParametrizacion = false;
  banderaImportarcionMasiva = false;
  banderaImportarcionCasas = false;
  banderaImportarcionResidentes = false;
  banderaImportarcionConceptosIngresos = false;
  banderaImportarcionConceptosGastos = false;



  //RESIDENTE
  banderaAccesoResidenteAgendarVisita = false;
  banderaAccesoResidenteHistorialDePagos = false;
  banderaAccesoResidenteAvisos = false;
  banderaAccesoResidenteQuejas = false;
  banderaAccesoResidenteSugerencia = false;
  banderaAccesoResidenteServicios = false;
  banderaAccesoResidenteImprimirRecibos = false;
  tokenFull: any;
  ngOnInit(): void {
    /*     this.tokenFull = localStorage.getItem("tokenVisitasSystem");
        if (!this.tokenFull) {
          sessionStorage.clear();
        } else {
          this.authService.refreshToken({ refreshToken: this.tokenFull }).toPromise().then((resp: any) => {
            localStorage.setItem('isLoggedin', 'true');
            localStorage.setItem("tokenVisitasSystem", resp.token);
            console.log(" REFRESH");
    
          }).catch((err: any) => {
            console.log(err);
    
          });
        }
    console.log("DESPUES DEL REFRESH"); */

    this.token = decodeToken(localStorage.getItem("tokenVisitasSystem"));
    this.permisosAccesos = this.token.permisosAccesos;
    this.is_empresa = this.token.is_empresa;

    this.paginaWeb = this.token.paginaWeb;
    this.nombreEmpresa = this.token.nombreEmpresa;
    this.imagenEmpresa = this.token.imagenEmpresa;
    /* 
        console.log(this.permisosAccesos); */
    let menuSidebar: any = [{
      label: 'Modulos',
      isTitle: true
    }
    ];





    //ADMINISTRACION
    for (let index = 0; index < this.permisosAccesos.length; index++) {
      let acceso = this.permisosAccesos[index];

      //ADMINISTRACION
      if (acceso == "ADMINISTRACION_CERRADA_O_EMPRESA_KEY") { this.banderaAccesoAdministracionCerrada = true; }
      if (acceso == "ADMINISTRACION_CASA_O_DEPARTAMENTO_KEY") { this.banderaAccesoAdministracionCasa = true; }
      if (acceso == "ADMINISTRACION_RESIDENTE_O_EMPLEADO_KEY") { this.banderaAccesoAdministracionResidente = true; }
      if (acceso == "ADMINISTRACION_ADMINISTRADOR_CERRADA_O_EMPRESA_KEY") { this.banderaAccesoAdministracionAdministradoresCerradas = true; }
      if (acceso == "ADMINISTRACION_TIPO_DE_USUARIO_KEY") { this.banderaAccesoAdministracionTipoDeUsuarios = true; }
      if (acceso == "ADMINISTRACION_ACCESOS_KEY") { this.banderaAccesoAdministracionAccesos = true; }


      //PUNTO DE VENTA


      if (acceso == "PV_CATALOGOS_CONCEPTOS_INGRESOS_KEY") { this.puntoDeVentaConceptosIngreso = true; }//SUBMENU CATALOGOS
      if (acceso == "PV_CATALOGOS_PROVEEDORES_KEY") { this.puntoDeVentaProveedores = true; }//SUBMENU CATALOGOS
      if (acceso == "PV_CATALOGOS_CONCEPTOS_EGRESOS_KEY") { this.puntoDeVentaConceptosEgresos = true; }//SUBMENU CATALOGOS
      if (acceso == "PV_REPORTES_FINANZAS_CERRADA_O_EMPRESA") { this.puntoDeVentaReporteDeFinanzasCerradaOEmpresa = true; }//SUBMENU REPORTES
      if (acceso == "PV_REPORTES_FINANZAS_DETALLADAS_KEY") { this.puntoDeVentaReporteDeFinanzasDetalladas = true; }//SUBMENU REPORTES
      if (acceso == "PV_BUSCAR_FOLIO_KEY") { this.puntoDeVentaBuscarFolio = true; }
      if (acceso == "PV_REGISTRAR_VENTA_KEY") { this.puntoDeVentaRegistrarVenta = true; }
      if (acceso == "PV_REGISTRAR_COMPRA_KEY") { this.puntoDeVentaRegistrarCompra = true; }
      if (acceso == "PV_CARGA_DE_DOCUMENTOS_KEY") { this.puntoDeVentaCargaDeDocumentos = true; }//SUBMENU CATALOGOS

      if (acceso == "PV_CORTE_DEL_DIA_KEY") { this.puntoDeVentaCorteDelDia = true; }
      if (acceso == "PV_HISTORICO_KEY") { this.puntoDeVentaHistorico = true; }
      if (acceso == "PV_CANCELACION_DE_MOVIMIENTOS_KEY") { this.puntoDeVentaCancelacionDeMovimientos = true; }


      //VISITAS
      if (acceso == "VISITAS_LEER_ENTRADA_KEY") { this.banderaAccesoVisitasLeerEntrada = true; }
      if (acceso == "VISITAS_LEER_SALIDA_KEY") { this.banderaAccesoVisitasLeerSalida = true; }
      if (acceso == "VISITAS_VISITA_EN_SITIO_KEY") { this.banderaAccesoVisitasVisitaEnSitio = true; }
      if (acceso == "VISITAS_VISITA_AGENDADA_KEY") { this.banderaAccesoVisitasVisitaAgendada = true; }
      if (acceso == "VISITAS_MONITOREO_KEY") { this.banderaAccesoVisitasMonitoreo = true; }
      if (acceso == "VISITAS_REPORTE_KEY") { this.banderaAccesoVisitasReporte = true; }
      if (acceso == "VISITAS_CANCELACION_DE_VISITAS_KEY") { this.banderaAccesoVisitasCancelacionDeVisitas = true; }
      if (acceso == "VISITAS_LOG_VISITAS_CANCELADAS_KEY") { this.banderaAccesoVisitasVisitasCanceladas = true; }
      if (acceso == "VISITAS_VALIDACION_DE_CHECKLIST_KEY") { this.banderaAccesoVisitasValidacionDeCheckList = true; }
      if (acceso == "VISITAS_CATALOGOS_ITEMS_CHECKLIST_KEY") { this.baderaVisitasCatalogosItemsChecklist = true; }//SUBMENU CATALOGOS
      if (acceso == "VISITAS_REPORTES_ACCESOS_KEY") { this.baderaVisitasReportesAccesos = true; }//SUBMENU REPORTES
      if (acceso == "VISITAS_REPORTES_VISITAS_KEY") { this.baderaVisitasReportesVisitas = true; }//SUBMENU REPORTES
      if (acceso == "VISITAS_VISITAS_ARCHIVADAS_KEY") { this.banderaAccesoVisitasArchivadas = true; }
      if (acceso == "VISITAS_LEER_QR_KEY") { this.banderaAccesoVisitasLeerQR = true; }
      if (acceso == "VISITAS_CATALOGOS_QR_KEY") { this.banderaVisitasCatalogosCodigosQR = true; }


      //BUZON
      if (acceso == "BUZON_AVISOS_KEY") { this.banderaAccesoBuzonAvisos = true; }
      if (acceso == "BUZON_QUEJAS_KEY") { this.banderaAccesoBuzonQuejas = true; }
      if (acceso == "BUZON_SUGERENCIAS_KEY") { this.banderaAccesoBuzonSugerencias = true; }

      //OTRO
      if (acceso == "OTRO_PARAMETRIZACION_KEY") { this.banderaAccesoOtroParametrizacion = true; }
      if (acceso == "OTRO_IMPORTACION_MASIVA_KEY") { this.banderaImportarcionMasiva = true; }
      if (acceso == "OTRO_IMPORTACION_MASIVA_FLOWSERVE_KEY") { this.banderaImportarcionMasivaFlowserve = true; }

      //PANEL DE ACCESO
      if (acceso == "PANEL_DE_ACCESO_PANEL_KEY") { this.banderaPanelDeAccesoPanel = true; }
      if (acceso == "PANEL_DE_ACCESO_PUERTA_KEY") { this.banderaPanelDeAccesoPuerta = true; }
      if (acceso == "PANEL_DE_ACCESO_LECTOR_KEY") { this.banderaPanelDeAccesoLector = true; }



      //EXTRAS
      if (acceso == "EXTRAS_SERVICIOS_KEY") { this.banderaExtrasServicios = true; }



      //RESIDENTE
      if (acceso == "RESIDENTE_AGENDAR_VISITA_KEY") { this.banderaAccesoResidenteAgendarVisita = true; }
      if (acceso == "RESIDENTE_HISTORIAL_DE_PAGOS_KEY") { this.banderaAccesoResidenteHistorialDePagos = true; }
      if (acceso == "RESIDENTE_AVISOS_KEY") { this.banderaAccesoResidenteAvisos = true; }
      if (acceso == "RESIDENTE_QUEJAS_KEY") { this.banderaAccesoResidenteQuejas = true; }
      if (acceso == "RESIDENTE_SUGERENCIAS_KEY") { this.banderaAccesoResidenteSugerencia = true; }
      if (acceso == "RESIDENTE_SERVICIOS_KEY") { this.banderaAccesoResidenteServicios = true; }
      if (acceso == "RESIDENTE_RECIBOS_KEY") { this.banderaAccesoResidenteImprimirRecibos = true; }



      //GRUPO DE ACCESOS
      if (acceso == "GRUPOS_DE_ACCESO_ADMINISTRACION") { this.banderaAccesoGrupoDeAccesoAdministracion = true; }
      if (acceso == "GRUPOS_DE_ACCESO_BLOQUEO_DE_USUARIOS") { this.banderaAccesoGrupoDeAccesoBloqueoDeUsuarios = true; }



      //GRUPO DE ACCESOS
      if (acceso == "AREAS_COMUNES_ADMINISTRACION_KEY") { this.banderaAccesoAreasComunesAdministracion = true; }
      if (acceso == "AREAS_COMUNES_RESERVACION_KEY") { this.banderaAccesoAreasComunesReservacion = true; }
      if (acceso == "AREAS_COMUNES_CANCELACION_KEY") { this.banderaAccesoAreasComunesCancelacion = true; }

    }

    //ADMINISTRACION
    if (
      this.banderaAccesoAdministracionCerrada == true ||
      this.banderaAccesoAdministracionCasa == true ||
      this.banderaAccesoAdministracionResidente == true ||
      this.banderaAccesoAdministracionAdministradoresCerradas == true ||
      this.banderaAccesoAdministracionTipoDeUsuarios == true ||
      this.banderaAccesoAdministracionAccesos == true
    ) {
      this.banderaAdministracion = true;
    }
    let itemsAdministracion = {};
    let subItemsAdministracion = [];

    let cerrada_o_empresa_label = this.is_empresa ? "Empresa" : "Cerrada";
    let cerrada_o_empresa_text = this.is_empresa ? "empresa" : "cerrada";


    let casa_o_departamento_label = this.is_empresa ? "Departamento" : "Casa";
    let casa_o_departamento_text = this.is_empresa ? "departamento" : "casa";


    let residente_o_empleado_label = this.is_empresa ? "Empleado" : "Residente";
    let residente_o_empleado_text = this.is_empresa ? "empleado" : "residente";



    if (this.banderaAccesoAdministracionCerrada) { subItemsAdministracion.push({ label: cerrada_o_empresa_label, link: `/administracion/${cerrada_o_empresa_text}`, }) }
    if (this.banderaAccesoAdministracionCasa) { subItemsAdministracion.push({ label: casa_o_departamento_label, link: `/administracion/${casa_o_departamento_text}`, }) }
    if (this.banderaAccesoAdministracionResidente) { subItemsAdministracion.push({ label: residente_o_empleado_label, link: `/administracion/${residente_o_empleado_text}` }) }
    if (this.banderaAccesoAdministracionAdministradoresCerradas) { subItemsAdministracion.push({ label: `Personal Administrativo`, link: `/administracion/administrador-${cerrada_o_empresa_text}` }) }
    if (this.banderaAccesoAdministracionTipoDeUsuarios) { subItemsAdministracion.push({ label: `Tipo de Usuario`, link: `/administracion/tipo-de-usuario` }) }
    if (this.banderaAccesoAdministracionAccesos) { subItemsAdministracion.push({ label: `Accesos`, link: `/administracion/accesos` }) }
    if (this.banderaAdministracion) {
      itemsAdministracion = { label: `Administracion`, icon: `mail`, subItems: subItemsAdministracion }
      menuSidebar.push(itemsAdministracion);
    }




    //PUNTO DE VENTA


    if (
      this.puntoDeVentaConceptosIngreso == true ||
      this.puntoDeVentaProveedores == true ||
      this.puntoDeVentaConceptosEgresos == true
    ) {
      this.banderaSubMenuCatalogosPuntoDeVenta = true;
    }




    if (
      this.puntoDeVentaReporteDeFinanzasCerradaOEmpresa == true ||
      this.puntoDeVentaReporteDeFinanzasDetalladas == true
    ) {
      this.banderaSubMenuReportesPuntoDeVenta = true;
    }




    if (
      this.puntoDeVentaConceptosIngreso == true ||
      this.puntoDeVentaProveedores == true ||
      this.puntoDeVentaConceptosEgresos == true ||
      this.puntoDeVentaReporteDeFinanzas == true ||
      this.puntoDeVentaBuscarFolio == true ||
      this.puntoDeVentaRegistrarVenta == true ||
      this.puntoDeVentaRegistrarCompra == true ||
      this.puntoDeVentaCargaDeDocumentos == true ||
      this.puntoDeVentaCorteDelDia == true ||
      this.puntoDeVentaHistorico == true ||
      this.puntoDeVentaCancelacionDeMovimientos == true ||
      this.banderaSubMenuCatalogosPuntoDeVenta ||
      this.banderaSubMenuReportesPuntoDeVenta
    ) {
      this.banderaPuntoDeVenta = true;
    }
    let itemsPuntoDeVenta = {};
    let subItemsPuntoDeVenta = [];


    let itemsSubMenuReportesPuntoDeVenta = {};
    let subItemsSubMenuReportesPuntoDeVenta = [];
    if (this.puntoDeVentaReporteDeFinanzasCerradaOEmpresa) { subItemsSubMenuReportesPuntoDeVenta.push({ label: `Finanzas por ${cerrada_o_empresa_label}`, link: `/punto-de-venta/reportes/finanzas-por-${cerrada_o_empresa_text}`, }) }
    if (this.puntoDeVentaReporteDeFinanzasDetalladas) { subItemsSubMenuReportesPuntoDeVenta.push({ label: `Finanzas Detalladas`, link: `/punto-de-venta/reportes/finanzas-detalladas`, }) }
    if (this.banderaSubMenuReportesPuntoDeVenta) {
      itemsSubMenuReportesPuntoDeVenta = { label: `Reportes`, subItems: subItemsSubMenuReportesPuntoDeVenta }
      subItemsPuntoDeVenta.push(itemsSubMenuReportesPuntoDeVenta);
    }


    let itemsSubMenuCatalogosPuntoDeVenta = {};
    let subItemsSubMenuCatalogosPuntoDeVenta = [];

    if (this.puntoDeVentaConceptosIngreso) { subItemsSubMenuCatalogosPuntoDeVenta.push({ label: `Conceptos "Ingresos"`, link: `/punto-de-venta/catalogos/conceptos-ingreso`, }) }
    if (this.puntoDeVentaProveedores) { subItemsSubMenuCatalogosPuntoDeVenta.push({ label: `Proveedores`, link: `/punto-de-venta/catalogos/proveedores` }) }
    if (this.puntoDeVentaConceptosEgresos) { subItemsSubMenuCatalogosPuntoDeVenta.push({ label: `Conceptos "Egresos"`, link: `/punto-de-venta/catalogos/conceptos-egreso` }) }
    if (this.banderaSubMenuCatalogosPuntoDeVenta) {
      itemsSubMenuCatalogosPuntoDeVenta = { label: `Catalogos`, subItems: subItemsSubMenuCatalogosPuntoDeVenta }
      subItemsPuntoDeVenta.push(itemsSubMenuCatalogosPuntoDeVenta);
    }

    if (this.puntoDeVentaBuscarFolio) { subItemsPuntoDeVenta.push({ label: `Buscar Folio`, link: `/punto-de-venta/buscar-folio`, }) }
    if (this.puntoDeVentaRegistrarVenta) { subItemsPuntoDeVenta.push({ label: `Registrar Venta`, link: `/punto-de-venta/registrar-venta` }) }
    if (this.puntoDeVentaRegistrarCompra) { subItemsPuntoDeVenta.push({ label: `Registrar Compra`, link: `/punto-de-venta/registrar-compra` }) }

    if (this.puntoDeVentaCargaDeDocumentos) { subItemsPuntoDeVenta.push({ label: `Carga de Documentos`, link: `/punto-de-venta/carga-de-documentos` }) }
    if (this.puntoDeVentaCorteDelDia) { subItemsPuntoDeVenta.push({ label: `Corte del Dia`, link: `/punto-de-venta/corte-del-dia` }) }
    if (this.puntoDeVentaHistorico) { subItemsPuntoDeVenta.push({ label: `Historico`, link: `/punto-de-venta/historico` }) }
    if (this.puntoDeVentaCancelacionDeMovimientos) { subItemsPuntoDeVenta.push({ label: `Cancelacion de Movimientos`, link: `/punto-de-venta/cancelacion-de-movimientos` }) }

    if (this.banderaPuntoDeVenta) {
      itemsPuntoDeVenta = { label: `Punto de Venta`, icon: `mail`, subItems: subItemsPuntoDeVenta }
      menuSidebar.push(itemsPuntoDeVenta);
    }/* 
    console.log(subItemsPuntoDeVenta); */






    //VISITAS


    if (
      this.baderaVisitasCatalogosItemsChecklist == true ||
      this.banderaVisitasCatalogosCodigosQR == true
    ) {
      this.banderaSubMenuCatalogosVisitas = true;
    }

    if (

      this.baderaVisitasReportesAccesos == true,
      this.baderaVisitasReportesVisitas == true
    ) {
      this.banderaSubMenuReportesVisitas = true;

    }



    if (
      this.banderaAccesoVisitasLeerEntrada == true ||
      this.banderaAccesoVisitasArchivadas == true ||
      this.banderaAccesoVisitasLeerSalida == true ||
      this.banderaAccesoVisitasVisitaEnSitio == true ||
      this.banderaAccesoVisitasVisitaAgendada == true ||
      this.banderaAccesoVisitasMonitoreo == true ||
      this.banderaSubMenuReportesVisitas == true ||
      this.banderaAccesoVisitasCancelacionDeVisitas == true ||
      this.banderaAccesoVisitasVisitasCanceladas == true ||
      this.banderaAccesoVisitasValidacionDeCheckList == true ||
      this.banderaAccesoVisitasLeerQR == true ||
      this.banderaSubMenuCatalogosVisitas == true
    ) {
      this.banderaVisitas = true;
    }
    let itemsVisitas = {};
    let subItemsVisitas = [];


    let itemsSubMenuCatalogosVisitas = {};
    let subItemsSubMenuCatalogosVisitas = [];

    if (this.baderaVisitasCatalogosItemsChecklist) { subItemsSubMenuCatalogosVisitas.push({ label: `Items CheckList`, link: `/visitas/catalogos/items-checklist`, }) }
    if (this.banderaVisitasCatalogosCodigosQR) { subItemsSubMenuCatalogosVisitas.push({ label: `Codigos QR`, link: `/visitas/catalogos/codigos-qr`, }) }
    if (this.banderaSubMenuCatalogosVisitas) {
      itemsSubMenuCatalogosVisitas = { label: `Catalogos`, subItems: subItemsSubMenuCatalogosVisitas }
      subItemsVisitas.push(itemsSubMenuCatalogosVisitas);
    }


    let itemsSubMenuReportesVisitas = {};
    let subItemsSubMenuReportesVisitas = [];

    if (this.baderaVisitasReportesAccesos) { subItemsSubMenuReportesVisitas.push({ label: `Accesos`, link: `/visitas/reportes/reporte-accesos`, }) }
    if (this.baderaVisitasReportesVisitas) { subItemsSubMenuReportesVisitas.push({ label: `Visitas`, link: `/visitas/reportes/reporte-visitas`, }) }
    if (this.banderaSubMenuReportesVisitas) {
      itemsSubMenuReportesVisitas = { label: `Reportes`, subItems: subItemsSubMenuReportesVisitas }
      subItemsVisitas.push(itemsSubMenuReportesVisitas);
    }
    if (this.banderaAccesoVisitasLeerEntrada) { subItemsVisitas.push({ label: `Leer Entrada`, link: `/visitas/leer-entrada`, }) }
    if (this.banderaAccesoVisitasLeerQR) { subItemsVisitas.push({ label: `Leer Qr`, link: `/visitas/leer-qr` }) }
    if (this.banderaAccesoVisitasImprimirQR) { subItemsVisitas.push({ label: `Imprimir Qr`, link: `/visitas/imprimir-qr` }) }
    if (this.banderaAccesoVisitasValidacionDeCheckList) { subItemsVisitas.push({ label: `Validacion CheckList`, link: `/visitas/validacion-checklist` }) }
    if (this.banderaAccesoVisitasLeerSalida) { subItemsVisitas.push({ label: `Leer Salida`, link: `/visitas/leer-salida`, }) }
    if (this.banderaAccesoVisitasVisitaEnSitio) { subItemsVisitas.push({ label: `Visita en Sitio`, link: `/visitas/visita-en-sitio` }) }
    if (this.banderaAccesoVisitasVisitaAgendada) { subItemsVisitas.push({ label: `Visita Agendada`, link: `/visitas/visita-agendada` }) }
    if (this.banderaAccesoVisitasMonitoreo) { subItemsVisitas.push({ label: `Monitoreo`, link: `/visitas/monitoreo` }) }
    /* if (this.banderaAccesoVisitasReporte) { subItemsVisitas.push({ label: `Reporte`, link: `/visitas/reporte` }) } */
    if (this.banderaAccesoVisitasCancelacionDeVisitas) { subItemsVisitas.push({ label: `Cancelacion de Visitas`, link: `/visitas/cancelacion-de-visitas` }) }
    if (this.banderaAccesoVisitasVisitasCanceladas) { subItemsVisitas.push({ label: `Visitas Canceladas`, link: `/visitas/visitas-canceladas` }) }
    if (this.banderaAccesoVisitasArchivadas) { subItemsVisitas.push({ label: `Visitas Archivadas`, link: `/visitas/visitas-archivadas` }) }

    if (this.banderaVisitas) {
      itemsVisitas = { label: `Visitas`, icon: `mail`, subItems: subItemsVisitas }
      menuSidebar.push(itemsVisitas);
    }






    //BUZON
    if (
      this.banderaAccesoBuzonAvisos == true ||
      this.banderaAccesoBuzonQuejas == true ||
      this.banderaAccesoBuzonSugerencias == true
    ) {
      this.banderaBuzon = true;
    }
    let itemsBuzon = {};
    let subItemsBuzon = [];
    if (this.banderaAccesoBuzonAvisos) { subItemsBuzon.push({ label: `Avisos`, link: `/buzon/avisos`, }) }
    if (this.banderaAccesoBuzonQuejas) { subItemsBuzon.push({ label: `Quejas`, link: `/buzon/quejas` }) }
    if (this.banderaAccesoBuzonSugerencias) { subItemsBuzon.push({ label: `Sugerencias`, link: `/buzon/sugerencias` }) }
    if (this.banderaBuzon) {
      itemsBuzon = { label: `Buzon`, icon: `mail`, subItems: subItemsBuzon }
      menuSidebar.push(itemsBuzon);
    }





    //OTRO
    if (
      this.banderaAccesoOtroParametrizacion == true ||
      this.banderaImportarcionMasiva == true ||
      this.banderaImportarcionMasivaFlowserve == true
    ) {
      this.banderaOtro = true;
    }
    let itemsOtro = {};
    let subItemsOtro = [];
    if (this.banderaAccesoOtroParametrizacion) { subItemsOtro.push({ label: `Parametrizacion`, link: `/otro/parametrizacion` }) }
    if (this.banderaImportarcionMasiva) { subItemsOtro.push({ label: `Importacion Masiva`, link: `/otro/importacion-casas-residentes` }) }
    if (this.banderaImportarcionMasivaFlowserve) { subItemsOtro.push({ label: `Importacion Masiva Flowserve`, link: `/otro/importacion-masiva-flowserve` }) }
    if (this.banderaOtro) {
      itemsOtro = { label: `Otro`, icon: `mail`, subItems: subItemsOtro }
      menuSidebar.push(itemsOtro);
    }




    //RESIDENTE
    if (
      this.banderaAccesoResidenteAgendarVisita == true ||
      this.banderaAccesoResidenteHistorialDePagos == true ||
      this.banderaAccesoResidenteAvisos == true ||
      this.banderaAccesoResidenteQuejas == true ||
      this.banderaAccesoResidenteSugerencia == true ||
      this.banderaAccesoResidenteServicios == true ||
      this.banderaAccesoResidenteImprimirRecibos == true
    ) {
      this.banderaResidente = true;
    }
    let itemsResidente = {};
    let subItemsResidente = [];
    if (this.banderaAccesoResidenteAgendarVisita) { subItemsResidente.push({ label: `Agendar Visita`, link: `/${residente_o_empleado_text}/agendar-visitas` }) }
    if (this.banderaAccesoResidenteAvisos) { subItemsResidente.push({ label: `Avisos`, link: `/${residente_o_empleado_text}/avisos` }) }
    if (this.banderaAccesoResidenteHistorialDePagos) { subItemsResidente.push({ label: `Historial de Pagos`, link: `/${residente_o_empleado_text}/historial-de-pagos` }) }
    if (this.banderaAccesoResidenteQuejas) { subItemsResidente.push({ label: `Quejas`, link: `/${residente_o_empleado_text}/quejas` }) }
    if (this.banderaAccesoResidenteSugerencia) { subItemsResidente.push({ label: `Sugerencias`, link: `/${residente_o_empleado_text}/sugerencias` }) }
    if (this.banderaAccesoResidenteServicios) { subItemsResidente.push({ label: `Servicios`, link: `/${residente_o_empleado_text}/servicios` }) }
    if (this.banderaAccesoResidenteImprimirRecibos) { subItemsResidente.push({ label: `Recibos`, link: `/${residente_o_empleado_text}/recibos` }) }
    if (this.banderaResidente) {
      itemsResidente = { label: `${residente_o_empleado_label}`, icon: `mail`, subItems: subItemsResidente }
      menuSidebar.push(itemsResidente);
    }

    //GRUPOS DE ACCESO

    if (
      this.banderaAccesoGrupoDeAccesoAdministracion == true ||
      this.banderaAccesoGrupoDeAccesoBloqueoDeUsuarios == true
    ) {
      this.banderaGruposDeAcceso = true;
    }
    let itemsGrupoDeAccesos = {};
    let subItemsGrupoDeAccesos = [];
    if (this.banderaAccesoGrupoDeAccesoAdministracion) { subItemsGrupoDeAccesos.push({ label: `Administracion`, link: `/grupos-de-acceso/administracion` }) }
    if (this.banderaAccesoGrupoDeAccesoBloqueoDeUsuarios) { subItemsGrupoDeAccesos.push({ label: `Bloqueo de Usuarios`, link: `/grupos-de-acceso/bloqueo-de-usuarios` }) }

    if (this.banderaGruposDeAcceso) {
      itemsGrupoDeAccesos = { label: `Grupos de Acceso`, icon: `mail`, subItems: subItemsGrupoDeAccesos }
      menuSidebar.push(itemsGrupoDeAccesos);
    }


    //EXTRAS

    if (
      this.banderaExtrasServicios == true
    ) {
      this.banderaExtras = true;
    }
    let itemsExtras = {};
    let subItemsExtras = [];
    if (this.banderaExtrasServicios) { subItemsExtras.push({ label: `Servicios`, link: `/extras/servicios` }) }


    if (this.banderaExtras) {
      itemsExtras = { label: `Extras`, icon: `mail`, subItems: subItemsExtras }
      menuSidebar.push(itemsExtras);
    }





    //PANEL DE ACCESO

    if (
      this.banderaPanelDeAccesoPanel == true ||
      this.banderaPanelDeAccesoPuerta == true ||
      this.banderaPanelDeAccesoLector == true
    ) {
      this.banderaPanelDeAcceso = true;
    }
    let itemsPanelDeAcceso = {};
    let subItemsPanelDeAcceso = [];
    if (this.banderaPanelDeAccesoPanel) { subItemsPanelDeAcceso.push({ label: `Panel`, link: `/panel-de-acceso/panel` }) }
    if (this.banderaPanelDeAccesoLector) { subItemsPanelDeAcceso.push({ label: `Lector`, link: `/panel-de-acceso/lector` }) }
    if (this.banderaPanelDeAccesoPuerta) { subItemsPanelDeAcceso.push({ label: `Puerta`, link: `/panel-de-acceso/puerta` }) }


    if (this.banderaPanelDeAcceso) {
      itemsPanelDeAcceso = { label: `Panel de Acceso`, icon: `mail`, subItems: subItemsPanelDeAcceso }
      menuSidebar.push(itemsPanelDeAcceso);
    }



    //AREAS COMUNES
    if (
      this.banderaAccesoAreasComunesAdministracion == true ||
      this.banderaAccesoAreasComunesReservacion == true ||
      this.banderaAccesoAreasComunesCancelacion == true
    ) {
      this.banderaAreasComunes = true;
    }
    let itemsAreasComunes = {};
    let subItemsAreasComunes = [];
    if (this.banderaAccesoAreasComunesAdministracion) { subItemsAreasComunes.push({ label: `Administracion`, link: `/areas-comunes/administracion` }) }
    if (this.banderaAccesoAreasComunesReservacion) { subItemsAreasComunes.push({ label: `Reservacion`, link: `/areas-comunes/reservacion` }) }
    if (this.banderaAccesoAreasComunesCancelacion) { subItemsAreasComunes.push({ label: `Cancelacion`, link: `/areas-comunes/cancelacion` }) }


    if (this.banderaAreasComunes) {
      itemsAreasComunes = { label: `Areas Comunes`, icon: `mail`, subItems: subItemsAreasComunes }
      menuSidebar.push(itemsAreasComunes);
    }

    this.menuItems = menuSidebar;













    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar 
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {

        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  };


}