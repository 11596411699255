import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keys } from 'src/environments/keys';

@Injectable({
  providedIn: 'root'
})
export class GenericoService {
  documentacionRemota() {    
    return this.http.post(keys.urlBackend + keys.tabla.generico + "/validar-si-la-documentacion-es-remota", null);

  }
 
  constructor(private http: HttpClient) { }

  public validarEmpresaOResidencial() {
    return this.http.post(keys.urlBackend + keys.tabla.generico + "/validar-si-es-empresa-o-no", null);
  }


  validarSiUsarContadorDeEmpleados() {
    return this.http.post(keys.urlBackend + keys.tabla.generico + "/validar-si-usar-contador-de-empleados", null);

  }
}
